/*--  COLOURS  --*/

.col-dark-grey {
	color: @dark-grey !important;
}

.col-mid-grey {
	color: @mid-grey !important;
}

.col-light-grey {
	color: @light-grey !important;
}

//*--  HIDDEN  --*//

.hidden {
	display: none !important;
}

//*--  ALIGN  --*//

.align-left {
	text-align: left !important;
}

.align-center {
	text-align: center !important;
}

.align-right {
	text-align: right !important;
}

//*--  TEXT  --*//

.all-caps {
	.all-caps();
}

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.capitalise {
	text-transform: capitalize;
}

.nowrap {
	white-space: nowrap;
}
