.accordion {
	.accordion-item {
		border-top: 1px solid @light-grey;
		overflow: hidden;

		.accordion-item-label {
			padding: 15px;
			font-size: 14px;
			position: relative;
			background: @off-white;
			cursor: pointer;
			transition: background 0.2s linear 0s;
			display: flex;
			align-items: center;
			user-select: none;

			> .icon-tick {
				display: none;
			}

			&:hover {
				background: mix(@off-white, #ffffff, 50%);
			}

			&.align-center {
				justify-content: center;
			}
		}

		.accordion-item-content {
			background: #ffffff;
			// transition: all 0.2s ease-in-out 0s;
			border-radius: 0 0 3px 3px;
			height: 0;
			overflow: hidden;
			padding: 0 15px;
			opacity: 0;
			transform: scaleY(0);
			transform-origin: 50% 0;
			border-top: 1px solid @light-grey;
		}

		&.active {
			.accordion-item-content {
				height: auto;
				padding: 20px 15px;
				opacity: 1;
				transform: scaleY(1);
			}
		}

		&:last-child {
			border-bottom: 1px solid @light-grey;
		}
	}

	&.checklist-accordion {
		.accordion-item {
			.accordion-item-label h2 {
				display: flex;
				align-items: center;

				> .icon-tick {
					border: 1px solid @light-grey;
					height: 17px;
					width: 17px;
					display: inline-block;
					line-height: 15px;
					border-radius: 2px;
					text-align: center;
					font-size: 0;
					transition: all 0.2s ease-in-out 0s;
					background: #ffffff;
					margin-right: 10px;
				}
			}

			&.active {
				.accordion-item-label h2 {
					> .icon-tick {
						border-color: @pos-bg;
						border-color: var(--pos-bg);
						background: @pos-bg;
						background: var(--pos-bg);
						color: @pri-fg;
						color: var(--pri-fg);
						font-size: 10px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: @screen-xs-min) {
	.accordion {
		.accordion-item {
			.accordion-item-label {
				padding: 20px;
				font-size: 16px;
			}

			&.active {
				.accordion-item-content {
					padding-top: 25px;
				}
			}
		}

		&.checklist-accordion {
			.accordion-item {
				.accordion-item-label h2 {
					> .icon-tick {
						height: 21px;
						width: 21px;
						left: 20px;
						line-height: 19px;
						margin-right: 15px;
					}
				}

				&.active {
					.accordion-item-label h2 {
						> .icon-tick {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: @screen-sm-min) {
	.accordion {
		max-width: 600px;
		margin: 0 auto;

		.accordion-item {
			border-left: 1px solid @light-grey;
			border-right: 1px solid @light-grey;

			.accordion-item-label {
				border-top: none;
				padding: 30px;
				font-size: 18px;
			}

			.accordion-item-content {
				padding-left: 50px;
				padding-right: 50px;
			}

			&.active {
				.accordion-item-content {
					padding-left: 50px;
					padding-right: 50px;
				}
			}

			&:first-child {
				border-radius: 3px 3px 0 0;
			}

			&:last-child {
				border-radius: 0 0 3px 3px;
			}
		}

		&.checklist-accordion {
			.accordion-item {
				.accordion-item-label h2 {
					> .icon-tick {
						margin-right: 20px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: @screen-md-min) {
	.accordion {
		max-width: 1000px;
	}
}
