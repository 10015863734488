iframe.threeDs {
	width: 370px;
	height: 366px;
	margin: -183px 0 0 -185px;
	position: fixed;
	top: 50%;
	left: 50%;
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
	transform: scale(0.85);
	background: #fff;
}

@media screen and (min-width: @screen-xs-min) {
	iframe.threeDs {
		transform: none;
	}
}
