.placeholder {
	color: @light-grey;
	min-height: 162px;
	padding: 30px 10px;

	.placeholder-inner {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		text-align: center;
		transform: translateY(-50%);

		i {
			display: block;
			font-size: 90px;
		}

		p {
			.all-caps();
			font-size: 12px;
			font-weight: bold;
			padding: 20px 0 0;
		}
	}
}
