.vc_root > div > div:not(.notification-container) {
	bottom: 60px;
}

@media screen and (min-width: @screen-sm-min) {
	.vc_root > div > div:not(.notification-container) {
		bottom: 80px;
	}
}

@media screen and (min-width: @screen-md-min) {
	.vc_root > div > div:not(.notification-container) {
		bottom: 100px;
	}
}
