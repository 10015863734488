@roboto-font-path: '../fonts';

.roboto-font(@folder, @variant, @type, @weight, @style) {
	@font-full-path: '@{roboto-font-path}/@{folder}/@{variant}';

	@font-face {
		font-family: '@{variant}';
		src: url('@{font-full-path}-@{type}.eot');
		src: local('@{variant} @{type}'), local('@{variant}-@{type}'),
			url('@{font-full-path}-@{type}.eot?#iefix') format('embedded-opentype'),
			url('@{font-full-path}-@{type}.woff2') format('woff2'), url('@{font-full-path}-@{type}.woff') format('woff'),
			url('@{font-full-path}-@{type}.ttf') format('truetype'),
			url('@{font-full-path}-@{type}.svg#@{variant}') format('svg');
		font-weight: @weight;
		font-style: @style;
	}

	@font-face {
		font-family: '@{variant}-@{type}';
		src: url('@{font-full-path}-@{type}.eot');
		src: local('@{variant} @{type}'), local('@{variant}-@{type}'),
			url('@{font-full-path}-@{type}.eot?#iefix') format('embedded-opentype'),
			url('@{font-full-path}-@{type}.woff2') format('woff2'), url('@{font-full-path}-@{type}.woff') format('woff'),
			url('@{font-full-path}-@{type}.ttf') format('truetype'),
			url('@{font-full-path}-@{type}.svg#@{variant}') format('svg');
	}
}

.roboto-font('roboto', 'Roboto', 'Regular', 400, normal);
.roboto-font('roboto', 'Roboto', 'Medium', 500, normal);
.roboto-font('roboto', 'Roboto', 'Bold', 700, normal);
