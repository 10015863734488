.written-content {
	> h3,
	> h4 {
		font-weight: 700;
		padding: 10px 0 15px;
		line-height: 1.5em;
		color: @dark-grey;
		font-size: 14px;
	}

	> p {
		line-height: 1.5em;
		color: @mid-grey;
		padding: 0 0 15px;
		font-size: 12px;

		> strong,
		> a {
			font-weight: 700;
		}

		&:first-child {
			padding-top: 5px;
		}
	}

	> ul {
		color: @mid-grey;
		line-height: 1.5em;
		list-style: disc;
		font-size: 12px;
		padding-left: 20px;

		> li {
			padding: 0 0 15px;

			> strong,
			> a {
				font-weight: 700;
			}

			> ul {
				list-style: circle;
				font-size: inherit;
				padding-left: 20px;
				li {
					padding: 0 0 15px;
				}
			}

			&.no-bullet {
				list-style: none;
			}
		}

		&.bullet-ol {
			list-style: none;
			padding-bottom: 15px;
			padding-left: 0;
			color: @dark-grey;
			font-weight: 700;

			> li {
				position: relative;
				padding-left: 35px;

				> .bullet {
					position: absolute;
					top: 0;
					left: 0;
					display: inline-block;
					width: 20px;
					height: 20px;
					line-height: 20px;
					text-align: center;
					font-weight: 700;
					border-radius: 100%;
					margin-right: 10px;
				}
			}
		}
	}

	&.small-print {
		> p {
			color: black;
			font-size: 12px;
		}
	}

	&.padded {
		padding: 10px 20px 0;
	}
}

@media screen and (min-width: @screen-sm-min) {
	.written-content {
		> h3,
		> h4 {
			font-size: 16px;
			padding: 10px 0 20px;
		}

		> p {
			font-size: 14px;
			padding: 0 0 20px;

			&:first-child {
				padding-top: 10px;
			}
		}

		> ul {
			font-size: 14px;
			padding-left: 25px;

			> li {
				padding: 0 0 20px;

				> ul {
					padding-left: 25px;

					li {
						padding: 0 0 25px;
					}
				}
			}

			&.bullet-ol {
				padding-bottom: 20px;
				padding-left: 20px;

				> li {
					padding-left: 40px;

					> .bullet {
						width: 24px;
						height: 24px;
						line-height: 24px;
					}
				}
			}
		}

		&.padded {
			padding: 20px 30px 10px;
		}
	}
}
