@import url(responsive-visibility.less);

@-ms-viewport {
	width: device-width;
}

.visible-xxs,
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
	.responsive-invisibility();
}

.visible-xxs-block,
.visible-xxs-inline,
.visible-xxs-inline-block,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-xl-block,
.visible-xl-inline,
.visible-xl-inline-block {
	display: none !important;
}

.visible-xxs {
	@media (max-width: @screen-xxs-max) {
		.responsive-visibility();
	}
}

.visible-xxs-block {
	@media (max-width: @screen-xxs-max) {
		display: block !important;
	}
}

.visible-xxs-inline {
	@media (max-width: @screen-xxs-max) {
		display: inline !important;
	}
}

.visible-xxs-inline-block {
	@media (max-width: @screen-xxs-max) {
		display: inline-block !important;
	}
}

.visible-xs {
	@media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
		.responsive-visibility();
	}
}

.visible-xs-block {
	@media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
		display: block !important;
	}
}

.visible-xs-inline {
	@media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
		display: inline !important;
	}
}

.visible-xs-inline-block {
	@media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
		display: inline-block !important;
	}
}

.visible-sm {
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		.responsive-visibility();
	}
}

.visible-sm-block {
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		display: block !important;
	}
}

.visible-sm-inline {
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		display: inline !important;
	}
}

.visible-sm-inline-block {
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		display: inline-block !important;
	}
}

.visible-md {
	@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
		.responsive-visibility();
	}
}

.visible-md-block {
	@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
		display: block !important;
	}
}

.visible-md-inline {
	@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
		display: inline !important;
	}
}

.visible-md-inline-block {
	@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
		display: inline-block !important;
	}
}

.visible-lg {
	@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
		.responsive-visibility();
	}
}

.visible-lg-block {
	@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
		display: block !important;
	}
}

.visible-lg-inline {
	@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
		display: inline !important;
	}
}

.visible-lg-inline-block {
	@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
		display: inline-block !important;
	}
}

.visible-xl {
	@media (min-width: @screen-xl-min) {
		.responsive-visibility();
	}
}

.visible-xl-block {
	@media (min-width: @screen-xl-min) {
		display: block !important;
	}
}

.visible-xl-inline {
	@media (min-width: @screen-xl-min) {
		display: inline !important;
	}
}

.visible-xl-inline-block {
	@media (min-width: @screen-xl-min) {
		display: inline-block !important;
	}
}

.hidden-xxs {
	@media (max-width: @screen-xxs-max) {
		.responsive-invisibility();
	}
}

.hidden-xs {
	@media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
		.responsive-invisibility();
	}
}

.hidden-sm {
	@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
		.responsive-invisibility();
	}
}

.hidden-md {
	@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
		.responsive-invisibility();
	}
}

.hidden-lg {
	@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
		.responsive-invisibility();
	}
}

.hidden-xl {
	@media (min-width: @screen-xl-min) {
		.responsive-invisibility();
	}
}

@media screen {
	.hidden-screen {
		display: none;
	}
}

// Print utilities

.visible-print {
	@media print {
		.responsive-visibility();
	}
}

.visible-print-block {
	@media print {
		display: block !important;
	}
}

.visible-print-inline {
	@media print {
		display: inline !important;
	}
}

.visible-print-inline-block {
	@media print {
		display: inline-block !important;
	}
}

.hidden-print {
	@media print {
		.responsive-invisibility();
	}
}
