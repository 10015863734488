.vehicle-title {
	display: flex;
	//justify-content: space-between;
	margin-bottom: 15px;

	.title-container {
		padding-right: 10px;
		width: calc(~'100% - 32px');

		.make-model,
		.variant {
			color: @dark-grey;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 18px;
			font-weight: 700;

			&.ellipsis {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		.variant {
			color: @text-hl;
			color: var(--text-hl);
			font-size: 10px;
			line-height: 14px;
			margin-top: 3px;
		}
	}

	.brand-container {
		width: 32px;

		img {
			max-width: 32px;
			max-height: 32px;
			margin-top: -2px;
		}
	}

	.price-container {
		width: 100px;
		text-align: right;

		h3 {
			white-space: nowrap;
			font-size: 14px;
			line-height: 18px;
			font-weight: 700;

			span {
				font-size: 10px;
			}
		}

		p {
			color: @mid-grey;
			font-size: 10px;
			line-height: 14px;
			margin-top: 3px;
		}
	}

	&.has-price {
		.title-container {
			width: calc(~'100% - 132px');
		}
	}
}
