.scroll-fade {
	position: fixed;
	left: 0;
	right: 0;
	height: 5px;
	z-index: 1;
	pointer-events: none;

	&.top {
		top: 80px;
		background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));

		&.has-tabs {
			top: 120px;
		}
	}

	&.bottom {
		bottom: 40px;
		background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));

		&.has-footer {
			bottom: 80px;
		}
	}
}

@media screen and (min-width: @screen-xs-min) {
	.scroll-fade {
		&.top {
			top: 100px;

			&.has-tabs {
				top: 150px;
			}
		}

		&.bottom {
			bottom: 50px;

			&.has-footer {
				bottom: 100px;
			}
		}
	}
}

@media screen and (min-width: @screen-sm-min) {
	.scroll-fade {
		&.top {
			top: 120px;

			&.has-tabs {
				top: 180px;
			}
		}

		&.bottom {
			bottom: 60px;

			&.has-footer {
				bottom: 120px;
			}
		}
	}
}

@media screen and (min-width: @screen-md-min) {
	.scroll-fade {
		&.top {
			top: 136px;

			&.has-tabs {
				top: 196px;
			}
		}

		&.bottom {
			bottom: 60px;

			&.has-footer {
				bottom: 120px;
			}
		}
	}
}

@media screen and (min-width: @screen-lg-min) {
	.scroll-fade {
		&.top {
			top: 156px;

			&.has-tabs {
				top: 216px;
			}
		}

		&.bottom {
			bottom: 80px;

			&.has-footer {
				bottom: 140px;
			}
		}
	}
}

@media screen and (min-width: @screen-xxs-min) and (max-width: @screen-xxs-max) and (max-height: @screen-height-xxs-min),
	screen and (min-width: @screen-xs-min) and (max-width: @screen-xs-max) and (max-height: @screen-height-xs-min),
	screen and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) and (max-height: @screen-height-sm-min),
	screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) and (max-height: @screen-height-md-min),
	screen and (min-width: @screen-lg-min) and (max-width: @screen-lg-max) and (max-height: @screen-height-lg-min),
	screen and (min-width: @screen-xl-min) and (max-height: @screen-height-xl-min) {
	.scroll-fade {
		display: none;
	}
}
