.blurb {
	line-height: 1.5em;
	color: @mid-grey;
	font-size: 12px;
	text-align: justify;
	padding: 0 0 20px 0;

	a {
		color: @text-hl;
		font-weight: 700;
	}
}

@media screen and (min-width: @screen-xs-min) {

	.blurb {
		font-size: 14px;
		padding: 0 0 30px 0;
	}
}

@media screen and (min-width: @screen-sm-min) {

	.blurb {
		font-size: 16px;
		padding: 0 0 35px 0;
	}
}
