.section {
	box-shadow: @shadow;
	width: 100%;
	flex: auto;
	background: #fff;
	padding: 40px 10px 10px;
	margin: 10px 0;
	position: relative;
	overflow: hidden;

	h2 {
		color: @pri-fg;
		color: var(--pri-fg);
		height: 30px;
		line-height: 28px;
		padding: 0 7px 0 10px;
		display: inline-block;
		font-size: 14px;
		position: absolute;
		top: 0;
		left: 0;

		.text {
			position: relative;
			z-index: 1;
			text-transform: capitalize;
			font-size: 12px !important;
		}

		.backdrop {
			background: @pri-bg;
			background: var(--pri-bg);
			position: absolute;
			top: 0;
			left: -20px;
			right: -5px;
			bottom: 0;
			transform: skewX(-15deg);
			display: block;
			z-index: 0;
		}
	}

	.section-links {
		position: absolute;
		top: 8px;
		right: 10px;
		display: inline-block;
		text-align: right;

		li {
			display: inline-block;
			margin-left: 20px;

			a {
				.all-caps();
				color: @text-hl;
				color: var(--text-hl);
				font-weight: bold;
				font-size: 10px;
				line-height: 12px;
				transition: all 0.2s linear 0s;
				cursor: pointer;

				i {
					position: relative;
					width: 12px;
					height: 12px;
					line-height: 12px;
					font-size: 12px;
					margin-right: 3px;
					top: 1px;
				}

				&:hover {
					opacity: 0.5;
				}
			}

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.loading-spinner {
		margin-top: -5px;
	}

	.section-inner {
		opacity: 1;
		transition: opacity 0.3s linear 0s;

		&.invisible {
			opacity: 0;
			transition: opacity 0s linear 0s;
		}
	}
}
