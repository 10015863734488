input[type='checkbox'] {
	display: none;
	user-select: none;

	+ label,
	+ .checkbox-label {
		font-size: 16px;
		padding-left: 35px;
		display: inline-block;
		line-height: 20px;
		position: relative;
		cursor: pointer;
		user-select: none;

		a {
			font-weight: 700;
			color: @text-hl;
			color: var(--text-hl);
			white-space: nowrap;
		}

		label {
			cursor: pointer;
		}

		&:hover {
			&::after {
				opacity: 1;
				color: @light-grey;
			}
		}
	}

	+ label,
	+ .checkbox-label label {
		> .check-background {
			display: block;
			height: 20px;
			width: 20px;
			border-radius: 3px;
			position: absolute;
			left: 0;
			top: 0;
			background: #ffffff;
			border: 1px solid @light-grey;
			z-index: 0;
			transition: all 0.2s linear 0s;
		}

		> .check-tick {
			display: block;
			.size-icon(12px);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			margin: 4px;
			opacity: 0;
			color: @light-grey;
			transition: all 0.2s linear 0s;
		}
	}

	&:checked {
		+ label,
		+ .checkbox-label label {
			> .check-background {
				background: @pos-bg;
				background: var(--pos-bg);
				border-color: @pos-bg;
				border-color: var(--pos-bg);
			}

			> .check-tick {
				color: @pos-fg;
				color: var(--pos-fg);
				opacity: 1;
			}
		}
	}
}

.icon-tick.checkbox {
	border: 1px solid @light-grey;
	height: 20px;
	width: 20px;
	display: inline-block;
	line-height: 18px;
	color: #ffffff;
	border-radius: 2px;
	text-align: center;
	font-size: 12px;
	transition: all 0.2s ease-in-out 0s;
	background: #ffffff;

	&::before {
		transform: scale(0);
		transition: inherit;
	}

	&.required {
		&::before {
			content: @padlock;
			color: @light-grey;
			transform: scale(1);
		}
	}

	&.checked {
		border: 1px solid;
		border-color: @pos-bg;
		border-color: var(--pos-bg);
		background: @pos-bg;
		background: var(--pos-bg);
		color: @pos-fg;
		color: var(--pos-fg);

		&::before {
			transform: scale(1);
		}

		&.required {
			&::before {
				color: @pos-fg;
				color: var(--pos-fg);
			}
		}
	}
}
