.highlighted-value {
	display: flex;

	.label,
	.value {
		line-height: 35px;
		padding: 0 10px;
		font-weight: bold;
		white-space: nowrap;
	}

	.label {
		.all-caps();
		background: @off-white;
		color: @dark-grey;
		margin-right: 4px;
		font-size: 10px;
		flex-basis: 100%;
	}

	.value {
		background: @sec-bg;
		color: @sec-fg;
		font-size: 16px;
		flex: auto;
	}

	&.large {

		.label,
		.value {
			height: 60px;
			line-height: 60px;
			padding: 0 20px;
			font-size: 18px;
		}

		.label {
			letter-spacing: @slim-spacing;
			font-size: 20px;
			text-transform: none;
		}

		.value {
			font-size: 22px;
		}
	}
}
