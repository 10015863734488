.sub-heading {
	padding-bottom: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	h3 {
		font-size: 14px;
	}
}

@media screen and (min-width: @screen-xs-min) {
	.sub-heading {
		padding-bottom: 20px;
	}
}

@media screen and (min-width: @screen-sm-min) {
	.sub-heading {
		h3 {
			font-size: 16px;
		}
	}
}

@media screen and (min-width: @screen-md-min) {
	.sub-heading {
		padding-bottom: 25px;
	}
}
