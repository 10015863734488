.code-block {
	background: @dark-grey;
	display: block;
	color: #fff;
	padding: 20px;
	font-size: 12px;
	font-family: 'Courier New', Courier, monospace;
	line-height: 1.3em;
	overflow-x: auto;
}

@media screen and (min-width: @screen-sm-min) {
	.code-block {
		border-radius: 3px;
		font-size: 13px;
		padding: 30px;
	}
}

@media screen and (min-width: @screen-md-min) {
	.code-block {
		padding: 40px;
	}
}

@media screen and (min-width: @screen-lg-min) {
	.code-block {
		padding: 50px;
	}
}
