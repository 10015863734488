@font-face {
	font-family: "icons";
	src: url('../fonts/icons.eot');
	src: url('../fonts/icons.eot?#iefix') format('eot'),
	url('../fonts/icons.woff2') format('woff2'),
	url('../fonts/icons.woff') format('woff'),
	url('../fonts/icons.ttf') format('truetype'),
	url('../fonts/icons.svg#icons') format('svg');
}

.icon-base-pseudo {
				  font-family: "icons";
				  -webkit-font-smoothing: antialiased;
				  -moz-osx-font-smoothing: grayscale;
				  font-style: normal;
				  font-variant: normal;
				  font-weight: normal;
				  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
				  text-decoration: none;
				  text-transform: none;
				  display: inline-block;
			  }

.char(@filename) {
@add: "\E001";
@arrow-down: "\E002";
@arrow-left: "\E003";
@arrow-right: "\E004";
@arrow-up: "\E005";
@bin: "\E006";
@breakdown: "\E007";
@calendar: "\E008";
@callback: "\E009";
@car: "\E00A";
@carpark: "\E00B";
@cash: "\E00C";
@chevron-down-circle: "\E00D";
@chevron-down: "\E00E";
@chevron-left-circle: "\E00F";
@chevron-left: "\E010";
@chevron-right-circle: "\E011";
@chevron-right: "\E012";
@chevron-up-circle: "\E013";
@chevron-up: "\E014";
@cog-spanner: "\E015";
@cross-circle: "\E016";
@cross: "\E017";
@door: "\E018";
@email: "\E019";
@engine-cc: "\E01A";
@engine: "\E01B";
@fuel: "\E01C";
@info: "\E01D";
@mileage: "\E01E";
@motorbike: "\E01F";
@no-car: "\E020";
@no-connection: "\E021";
@no-finance: "\E022";
@padlock: "\E023";
@pencil: "\E024";
@pinpoint: "\E025";
@play: "\E026";
@printer: "\E027";
@question-circle: "\E028";
@refresh: "\E029";
@save: "\E02A";
@search: "\E02B";
@send: "\E02C";
@telephone: "\E02D";
@tick: "\E02E";
@transmission: "\E02F";
@user-headset: "\E030";
@user: "\E031";
@van: "\E032";
@warning: "\E033";

content: @@filename;
}

.icon(@filename, @insert: before) {
	@pseudo-selector: ~":@{insert}";

	&@{pseudo-selector} {
		&:extend(.icon-base-pseudo);
		.char(@filename);
	}
}

.icon-add {
.icon(add);
}
.icon-arrow-down {
.icon(arrow-down);
}
.icon-arrow-left {
.icon(arrow-left);
}
.icon-arrow-right {
.icon(arrow-right);
}
.icon-arrow-up {
.icon(arrow-up);
}
.icon-bin {
.icon(bin);
}
.icon-breakdown {
.icon(breakdown);
}
.icon-calendar {
.icon(calendar);
}
.icon-callback {
.icon(callback);
}
.icon-car {
.icon(car);
}
.icon-carpark {
.icon(carpark);
}
.icon-cash {
.icon(cash);
}
.icon-chevron-down-circle {
.icon(chevron-down-circle);
}
.icon-chevron-down {
.icon(chevron-down);
}
.icon-chevron-left-circle {
.icon(chevron-left-circle);
}
.icon-chevron-left {
.icon(chevron-left);
}
.icon-chevron-right-circle {
.icon(chevron-right-circle);
}
.icon-chevron-right {
.icon(chevron-right);
}
.icon-chevron-up-circle {
.icon(chevron-up-circle);
}
.icon-chevron-up {
.icon(chevron-up);
}
.icon-cog-spanner {
.icon(cog-spanner);
}
.icon-cross-circle {
.icon(cross-circle);
}
.icon-cross {
.icon(cross);
}
.icon-door {
.icon(door);
}
.icon-email {
.icon(email);
}
.icon-engine-cc {
.icon(engine-cc);
}
.icon-engine {
.icon(engine);
}
.icon-fuel {
.icon(fuel);
}
.icon-info {
.icon(info);
}
.icon-mileage {
.icon(mileage);
}
.icon-motorbike {
.icon(motorbike);
}
.icon-no-car {
.icon(no-car);
}
.icon-no-connection {
.icon(no-connection);
}
.icon-no-finance {
.icon(no-finance);
}
.icon-padlock {
.icon(padlock);
}
.icon-pencil {
.icon(pencil);
}
.icon-pinpoint {
.icon(pinpoint);
}
.icon-play {
.icon(play);
}
.icon-printer {
.icon(printer);
}
.icon-question-circle {
.icon(question-circle);
}
.icon-refresh {
.icon(refresh);
}
.icon-save {
.icon(save);
}
.icon-search {
.icon(search);
}
.icon-send {
.icon(send);
}
.icon-telephone {
.icon(telephone);
}
.icon-tick {
.icon(tick);
}
.icon-transmission {
.icon(transmission);
}
.icon-user-headset {
.icon(user-headset);
}
.icon-user {
.icon(user);
}
.icon-van {
.icon(van);
}
.icon-warning {
.icon(warning);
}
