.highlighted-notice {
	border-radius: 3px;
	background: @light-yellow;
	padding: 15px 15px 5px;
	display: flex;

	> i {
		color: @neg-bg;
		color: var(--pri-bg);
		margin-right: 15px;
		font-size: 16px;
		margin-top: 3px;

		&.neg {
			color: @neg-bg;
			color: var(--neg-bg);
		}

		&.pos {
			color: @pos-bg;
			color: var(--pos-bg);
		}
	}

	> div {
		> p {
			line-height: 1.4em;
			color: @dark-grey;
			margin-bottom: 10px;
		}
	}

	&.plain {
		background: @off-white;
	}
}

@media screen and (min-width: @screen-xs-min) {
	.highlighted-notice {
		padding: 20px 20px 10px;

		> i {
			margin-right: 20px;
			font-size: 20px;
		}
	}
}
