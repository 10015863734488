//-----  VARIABLES  -----//

@system-font: 'Roboto', 'Trebuchet', Arial, sans-serif;

/*  DEFAULT BRANDING  */

// @pri-bg: #30405c;
// @pri-fg: #ffffff;
// @sec-bg: #f5801f;
// @sec-fg: #ffffff;
// @head-bg: #30405c;
// @head-fg: #ffffff;
// @text-hl: #f5801f;
// @pos-bg: #3ec567;
// @pos-fg: #ffffff;
// @warn-bg: #f5801f;
// @warn-fg: #ffffff;
// @neg-bg: #de3c3c;
// @neg-fg: #ffffff;

@pri-bg: #222;
@pri-fg: #fff;
@sec-bg: #444;
@sec-fg: #fff;
@head-bg: #fff;
@head-fg: #222;
@text-hl: #444;
@pos-bg: #3ec567;
@pos-fg: #fff;
@warn-bg: #f5801f;
@warn-fg: #fff;
@neg-bg: #de3c3c;
@neg-fg: #fff;

:root {
	--pri-bg: @pri-bg;
	--pri-fg: @pri-fg;
	--sec-bg: @sec-bg;
	--sec-fg: @sec-fg;
	--head-bg: @head-bg;
	--head-fg: @head-fg;
	--text-hl: @text-hl;
	--pos-bg: @pos-bg;
	--pos-fg: @pos-fg;
	--warn-bg: @warn-bg;
	--warn-fg: @warn-fg;
	--neg-bg: @neg-bg;
	--neg-fg: @neg-fg;

	--pri-bg-rgb: 34, 34, 34;
	--pri-fg-rgb: 255, 255, 255;
	--sec-bg-rgb: 68, 68, 68;
	--sec-fg-rgb: 255, 255, 255;
	--head-bg-rgb: 255, 255, 255;
	--head-fg-rgb: 34, 34, 34;
	--text-hl-rgb: 68, 68, 68;
	--pos-bg-rgb: 62, 197, 103;
	--pos-fg-rgb: 255, 255, 255;
	--warn-bg-rgb: 245, 128, 31;
	--warn-fg-rgb: 255, 255, 255;
	--neg-bg-rgb: 222, 60, 60;
	--neg-fg-rgb: 255, 255, 255;

	--credit-rating-section-one: #fb4343;
	--credit-rating-section-two: #fecb4b;
	--credit-rating-section-three: #74db51;
	--credit-rating-section-four: #61bd31;
	--credit-rating-section-five: #157818;
	--credit-rating-section-grey: #9b9b9b;

	/* colors */
	--pex-widget-bg: #f5f5f5;
	--pex-widget-primary-color: #151515;
	--pex-widget-heading-color: #252525;
	--pex-widget-text-color: #666;
	--pex-widget-border-color: #d4d4d4;
	--pex-widget-link-color: #4F46E5;
	/* widget */
	--pex-widget-font-size: 16px;
	--pex-widget-font: 'Inter', sans-serif;
	--pex-widget-spacing: 24px;
	--pex-widget-radius: 8px;
	--pex-widget-gap: 24px;
	/* form */
	--pex-widget-form-gap: 16px;
	--pex-widget-input-radius: 8px;
	--pex-widget-input-height: 56px;
	/* pex-widget-search  */
	--pex-widget-numberplate-font: "Arial", sans-serif;
	--pex-widget-numberplate-height: var(--pex-widget-input-height);
	--pex-widget-numberplate-bg: #ffd000;
	--pex-widget-numberplate-color: black;
	--pex-widget-numberplate-badge-bg: #1834cf;
	--pex-widget-numberplate-badge-content: #fff;
	/* mileage */
	--pex-widget-mileage-height: var(--pex-widget-input-height);
	--pex-widget-mileage-bg: #ffffff;
	--pex-widget-mileage-color: var(--pex-widget-text-color);
	--pex-widget-mileage-border: var(--pex-widget-border-color);

	/* button */
	--pex-widget-button-radius: var(--pex-widget-input-radius);
	--pex-widget-button-bg: var(--pex-widget-primary-color);
	--pex-widget-button-color: white;
	--pex-widget-button-border: transparent;
	--pex-widget-button-bg-hover: color-mix(in srgb,var(--pex-widget-button-bg),#fff 25%);
	--pex-widget-button-color-hover: white;
	--pex-widget-button-border-hover: transparent;
	/* badges */
	--pex-widget-badge-border: var(--pex-widget-border-color);
	--pex-widget-badge-bg: transparent;
	--pex-widget-badge-color: var(--pex-widget-heading-color);
	/* solid buttons */
	--pex-widget-solid-button-radius: 8px;
	--pex-widget-solid-button-gap: 4px;
	--pex-widget-solid-button-bg: var(--pex-widget-primary-color);
	--pex-widget-solid-button-color: #fff;
	--pex-widget-solid-button-border: var(--pex-widget-solid-button-bg);
	--pex-widget-solid-button-bg-hover: color-mix(in srgb,var(--pex-widget-solid-button-bg),#fff 25%);
	--pex-widget-solid-button-color-hover: #fff;
	--pex-widget-solid-button-border-hover: color-mix(in srgb,var(--pex-widget-solid-button-bg),#fff 25%);
	/* valuation buttons */
	--pex-widget-bordered-button-radius: 8px;
	--pex-widget-bordered-button-gap: 4px;
	--pex-widget-bordered-button-bg: transparent;
	--pex-widget-bordered-button-color: var(--pex-widget-heading-color);
	--pex-widget-bordered-button-border: var(--pex-widget-border-color);
	--pex-widget-bordered-button-bg-hover: var(--pex-widget-primary-color);
	--pex-widget-bordered-button-color-hover: #fff;
	--pex-widget-bordered-button-border-hover: var(--pex-widget-bordered-button-bg-hover);

	/* colors */
	--pex-form-widget-bg: #f5f5f5;
	--pex-form-widget-primary-color: #151515;
	--pex-form-widget-heading-color: #252525;
	--pex-form-widget-text-color: #666;
	--pex-form-widget-border: #d4d4d4;
	/* widget */
	--pex-form-widget-font-size: 16px;
	--pex-form-widget-font: 'Inter', sans-serif;
	--pex-form-widget-spacing: 24px;
	--pex-form-widget-radius: 8px;
	--pex-form-widget-gap: 16px;
	/* badges */
	--pex-form-widget-badge-border: var(--pex-form-widget-border);
	--pex-form-widget-badge-bg: transparent;
	--pex-form-widget-badge-color: var(--pex-form-widget-heading-color);
	/* solid buttons */
	--pex-form-widget-solid-button-radius: 8px;
	--pex-form-widget-solid-button-gap: 4px;
	--pex-form-widget-solid-button-bg: var(--pex-form-widget-primary-color);
	--pex-form-widget-solid-button-color: #fff;
	--pex-form-widget-solid-button-border: var(--pex-form-widget-solid-button-bg);
	--pex-form-widget-solid-button-bg-hover: color-mix(in srgb,var(--pex-form-widget-solid-button-bg),#fff 25%);
	--pex-form-widget-solid-button-color-hover: #fff;
	--pex-form-widget-solid-button-border-hover: color-mix(in srgb,var(--pex-form-widget-solid-button-bg),#fff 25%);
	/* valuation buttons */
	--pex-form-widget-bordered-button-radius: 8px;
	--pex-form-widget-bordered-button-gap: 4px;
	--pex-form-widget-bordered-button-bg: transparent;
	--pex-form-widget-bordered-button-color: var(--pex-form-widget-heading-color);
	--pex-form-widget-bordered-button-border: var(--pex-form-widget-border);
	--pex-form-widget-bordered-button-bg-hover: var(--pex-form-widget-primary-color);
	--pex-form-widget-bordered-button-color-hover: #fff;
	--pex-form-widget-bordered-button-border-hover: var(--pex-form-widget-bordered-button-bg-hover);

	/* pex card colors*/
	--fin-calc-pex-card-bg: #fff;
	--fin-calc-pex-card-primary-color: #151515;
	--fin-calc-pex-card-heading-color: #252525;
	--fin-calc-pex-card-text-color: #666;
	--fin-calc-pex-card-border: var(--fin-calc-pex-card-primary-color);
	--fin-calc-pex-card-link-color: #4F46E5;
	/* pex card */
	--fin-calc-pex-card-radius: 8px;
	--fin-calc-pex-card-padding: 12px;
	--fin-calc-pex-card-gap: 12px;
	/* badges */
	--fin-calc-pex-card-badge-border: #ccc;
	--fin-calc-pex-card-badge-bg: transparent;
	--fin-calc-pex-card-badge-color: var(--fin-calc-pex-card-heading-color);
	/* pex card close buttons */
	--fin-calc-pex-card-close-button-bg: transparent;
	--fin-calc-pex-card-close-button-color: var(--fin-calc-pex-card-primary-color);
	--fin-calc-pex-card-close-button-border: transparent;
	--fin-calc-pex-card-close-button-bg-hover: transparent;
	--fin-calc-pex-card-close-button-color-hover: color-mix(in srgb,var(--fin-calc-pex-card-close-button-color),#fff 25%);
	--fin-calc-pex-card-close-button-border-hover: color-mix(in srgb,var(--fin-calc-pex-card-primary-color),#fff 90%);
	/* pex card add buttons */
	--fin-calc-pex-card-add-button-radius: 8px;
	--fin-calc-pex-card-add-button-gap: 4px;
	--fin-calc-pex-card-add-button-bg: #fff;
	--fin-calc-pex-card-add-button-color: var(--fin-calc-pex-card-primary-color);
	--fin-calc-pex-card-add-button-border: color-mix(in srgb,var(--fin-calc-pex-card-primary-color),transparent 70%);
	--fin-calc-pex-card-add-button-bg-hover: color-mix(in srgb,var(--pex-widget-button-bg),#fff 95%);
	--fin-calc-pex-card-add-button-color-hover: var(--fin-calc-pex-card-primary-color);
	--fin-calc-pex-card-add-button-border-hover: var(--fin-calc-pex-card-primary-color);

	--pex-form-card-radius: 12px;
	--pex-form-card-spacing: 20px;
	--pex-form-card-radio-border-width: 2px;
	--pex-form-card-radio-size: 24px;
	/* colors */
	--pex-form-card-bg-color: #fff;
	--pex-form-card-bg-color-hover: #4F46E508;
	--pex-form-card-highlight-color: #6cb776;
	--pex-form-card-border: #D1D5DB;
	--pex-form-card-heading-color: #252525;
	--pex-form-card-text-color: #666;

	--pex-form-accordion-bg: #fff;
	--pex-form-accordion-bg-hover: #4F46E508;
	--pex-form-accordion-radius: 12px;
	--pex-form-accordion-spacing: 16px;
	--pex-form-accordion-radio-border-width: 2px;
	--pex-form-accordion-radio-size: 24px;
	/* colors */
	--pex-form-accordion-highlight-color: #6cb776;
	--pex-form-accordion-border-color: #D1D5DB;
	--pex-form-accordion-heading-color: #252525;
	--pex-form-accordion-text-color: #666;
}

/* FIXED COLOURS */

@dark-grey: #111111;
@mid-grey: #777777;
@light-mid-grey: #aaaaaa;
@light-grey: #cccccc;
@lighter-grey: #e6e6e6;
@off-white: #f6f6f6;
@yellow: #f2b521;
@light-yellow: #fef2cc;

/* DISPLAY AESTHETICS */

@shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
@mid-shadow: 0 2px 7px rgba(0, 0, 0, 0.25);
@large-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
@wide-spacing: 0.02em;
@slim-spacing: -0.02em;

//-----  SCREEN SIZES  -----//

// SCREEN SIZES  //

@screen-xxs: 0px;
@screen-xs: 375px;
@screen-sm: 640px;
@screen-md: 1020px;
@screen-lg: 1366px;
@screen-xl: 1700px;

// MINIMUM SCREEN SIZES  //

@screen-xxs-min: @screen-xxs;
@screen-xs-min: @screen-xs;
@screen-sm-min: @screen-sm;
@screen-md-min: @screen-md;
@screen-lg-min: @screen-lg;
@screen-xl-min: @screen-xl;

// MAXIMUM SCREEN SIZES //

@screen-xxs-max: (@screen-xs-min - 1);
@screen-xs-max: (@screen-sm-min - 1);
@screen-sm-max: (@screen-md-min - 1);
@screen-md-max: (@screen-lg-min - 1);
@screen-lg-max: (@screen-xl-min - 1);

//  SCREEN HEIGHT  //

@screen-height-xxs-min: 400px;
@screen-height-xs-min: 500px;
@screen-height-sm-min: 600px;
@screen-height-md-min: 600px;
@screen-height-lg-min: 700px;
@screen-height-xl-min: 700px;

//-----  ICONS  -----//

@add: '\E001';
@arrow-down: '\E002';
@arrow-left: '\E003';
@arrow-right: '\E004';
@arrow-up: '\E005';
@bin: '\E006';
@breakdown: '\E007';
@calendar: '\E008';
@callback: '\E009';
@car: '\E00A';
@carpark: '\E00B';
@cash: '\E00C';
@chevron-down-circle: '\E00D';
@chevron-down: '\E00E';
@chevron-left-circle: '\E00F';
@chevron-left: '\E010';
@chevron-right-circle: '\E011';
@chevron-right: '\E012';
@chevron-up-circle: '\E013';
@chevron-up: '\E014';
@cog-spanner: '\E015';
@cross-circle: '\E016';
@cross: '\E017';
@door: '\E018';
@email: '\E019';
@engine-cc: '\E01A';
@engine: '\E01B';
@fuel: '\E01C';
@info: '\E01D';
@mileage: '\E01E';
@motorbike: '\E01F';
@no-car: '\E020';
@no-connection: '\E021';
@no-finance: '\E022';
@padlock: '\E023';
@pencil: '\E024';
@pinpoint: '\E025';
@play: '\E026';
@printer: '\E027';
@refresh: '\E028';
@save: '\E029';
@search: '\E02A';
@send: '\E02B';
@telephone: '\E02C';
@tick: '\E02D';
@transmission: '\E02E';
@user: '\E02F';
@van: '\E030';
@warning: '\E031';
@question-circle: '\E032';

//-----  MIXINS  -----//

.placeholder(@color) {
	&::-webkit-input-placeholder {
		color: @color;
	}

	&::-moz-placeholder {
		color: @color;
	}

	&:-ms-input-placeholder {
		color: @color;
	}
}

.all-caps() {
	text-transform: uppercase;
	letter-spacing: 0.05em;
}

.border-radius(@tl,@tr,@br,@bl) {
	border-top-left-radius: @tl;
	border-top-right-radius: @tr;
	border-bottom-right-radius: @br;
	border-bottom-left-radius: @bl;
}

.circle-highlight() {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 120%;
	padding-top: 120%;
	transform: translate(-50%, -50%) scale(0);
	border-radius: 100%;
	transition: all 0.25s ease-in-out 0s;
	z-index: 0;
	opacity: 0;
	pointer-events: none;
}

.circle-highlight-hover() {
	opacity: 1;
	transform: translate(-50%, -50%) scale(1);
}

.size-icon(@size) {
	height: @size;
	width: @size;
	line-height: @size;
	font-size: @size;
}

.visibility-hidden {
	visibility: hidden;
}
