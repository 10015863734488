.tooltip {
	.all-caps();
	font-size: 10px;
	line-height: 14px;
	max-width: 200px;
	background-color: @dark-grey;
	color: @light-grey;
	text-align: center;
	border-radius: 3px;
	padding: 4px 8px 5px;
	position: fixed;
	z-index: 100000;
	display: block;
	opacity: 0;
	transform: scale(0);
	transition: all 0.2s ease-in-out 0s;
	transition-property: opacity, transform;

	&.show {
		opacity: 1;
		transform: scale(1);
	}

	&::after {
		content: "";
		position: absolute;
		border-style: solid;
	}

	&.position-top {
		transform-origin: 50% 100%;

		&::after {
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-color: @dark-grey transparent transparent transparent;
		}
	}

	&.position-bottom {
		transform-origin: 50% 0;

		&::after {
			bottom: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-color: transparent transparent @dark-grey transparent;
		}
	}

	&.position-left {
		transform-origin: 100% 50%;

		&::after {
			top: 50%;
			left: 100%;
			margin-top: -5px;
			border-width: 5px;
			border-color: transparent transparent transparent @dark-grey;
		}
	}

	&.position-right {
		transform-origin: 0 50%;

		&::after {
			top: 50%;
			right: 100%;
			margin-top: -5px;
			border-width: 5px;
			border-color: transparent @dark-grey transparent transparent;
		}
	}
}
