.data-table {
	border: none;
	border-bottom: 1px solid @light-grey;
	width: 100%;

	th, td {
		color: @dark-grey;
		padding: 5px 8px;
		font-size: 10px;
		vertical-align: top;
		text-align: left;
		font-weight: bold;
		line-height: 18px;

		&.align-center {
			text-align: center;
		}

		&.align-right {
			text-align: right;
		}

		&.double-height {
			padding: 19px 6px;
		}
	}

	thead {
		background: @off-white;
		border-bottom: 1px solid @light-grey;

		th {
			font-size: 8px;
			text-transform: uppercase;
		}
	}

	tbody {

		tr {

			&:nth-child(even) {

				td {
					background: @off-white;
				}
			}

			&.highlighted {

				td {
					background: @sec-bg;
					color: @sec-fg;
				}
			}
		}
	}
}
