@keyframes rotate-loader {
	0% {
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.loading-spinner {
	border: 3px solid;
	border-radius: 50%;
	content: '';
	display: inline-block;
	position: absolute;
	width: 50px;
	height: 50px;
	top: 50%;
	margin-top: -25px;
	margin-left: -25px;
	left: 50%;
	z-index: 1000;
	background: transparent;
	border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) @mid-grey;
	animation: rotate-loader 1s linear infinite;
}

.loading-overlay {
	background: rgba(255, 255, 255, 0.8);
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
}

.loading-message {
	text-transform: uppercase;
	letter-spacing: 0.2em;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%);
	color: @mid-grey;
	font-size: 13px;
	opacity: 0.7;
	margin-top: 50px;
}
