.link-list {
	margin: 8px;

	li {

		a {

			padding: 7px 10px;
			line-height: 1.4em;
			display: block;
			color: @mid-grey;
			font-size: 11px;
			cursor: pointer;
			border-radius: 3px;
			text-transform: uppercase;

			&:hover {
				background: @off-white;
				background: @off-white;
				color: @dark-grey;
			}
		}

		&.separator {
			height: 1px;
			background: @light-grey;
			margin: 10px 0;
		}
	}
}
