@font-face {
	font-family: 'uknumberplateregular';
	src: url('../fonts/uknumberplate-webfont.woff2') format('woff2'),
		url('../fonts/uknumberplate-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

.number-plate {
	background: @yellow;
	color: #000;
	display: inline-block;
	font-family: 'uknumberplateregular', sans-serif;
	border-radius: 3px;
	font-weight: normal;
	height: 16px;
	line-height: 16px;
	font-size: 11px;
	padding: 0 5px;
	text-align: center;

	&.number-plate-large {
		font-size: 14px;
		height: 18px;
		line-height: 18px;
		padding: 0 7px;
	}
}

@media screen and (min-width: @screen-sm-min) {
	.number-plate {
		&.number-plate-large {
			font-size: 20px;
			height: 28px;
			line-height: 28px;
			padding: 0 15px;
		}
	}
}
