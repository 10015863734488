html {
	overflow-y: scroll;
	overflow-x: hidden;
}

body {
	background: @lighter-grey;
	font-family: @system-font;
	padding: 0;

	&.scroll-lock {
		overflow: hidden;
		top: 0;
		bottom: 0;
		width: 100%;
		position: fixed;
	}
}

.app-loading {
	background: @lighter-grey;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

h1,
h2,
h3,
h4,
h5 {
	color: @dark-grey;
	font-weight: 600;
	letter-spacing: @slim-spacing;
}

h3 {
	font-size: 14px;
}

h3.subheading {
	color: @dark-grey;
	letter-spacing: @slim-spacing;
	font-weight: 700;
	position: relative;
	padding-top: 10px;
	margin-bottom: 25px;
	font-size: 16px;

	&::after {
		background: @sec-bg;
		content: '';
		position: absolute;
		bottom: -8px;
		left: 0;
		width: 25px;
		height: 3px;
	}

	&.align-center {
		text-align: center;

		&::after {
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

p {
	font-size: 12px;
	color: @mid-grey;
}

strong {
	font-weight: 700;
}

@media screen and (min-width: @screen-md-min) {
	p {
		font-size: 14px;
	}
}
