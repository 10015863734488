.form {
	.form-control {
		margin-bottom: 15px;
	}
}

@media screen and (min-width: @screen-sm-min) {
	.form {
		.form-control {
			margin-bottom: 25px;
		}
	}
}
