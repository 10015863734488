.key-value {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.key {
		font-size: 14px;
		font-weight: 700;
		text-align: left;
	}

	.value {
		font-size: 14px;
		font-weight: 700;
		text-align: right;
		flex-shrink: 0;
	}

	&.large {
		.key {
			font-size: 16px;
		}

		.value {
			font-size: 20px;
		}
	}
}

.key-value-list {
	padding: 10px 25px;

	li {
		.key-value;
		position: relative;
		align-items: flex-start;
		padding-top: 15px;

		> div {
			padding: 0 0 15px;
			flex: auto;
			white-space: nowrap;
			line-height: 18px;

			p {
				font-weight: 400;
				color: @mid-grey;
				font-size: 12px;
				line-height: 1.3em;
				padding-top: 5px;
				white-space: normal;
			}

			&.key {
				padding-right: 15px;
			}
		}

		&::after {
			content: '';
			position: absolute;
			height: 1px;
			left: 0;
			right: 0;
			bottom: 0;
			background: @light-grey;
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}

	&.with-icons {
		li {
			[class^='icon'],
			[class*='icon'] {
				font-size: 18px;
				height: 18px;
				width: 36px;
				padding-right: 18px;
			}

			&::after {
				left: 36px;
			}
		}
	}

	&.striped {
		padding: 0;

		li {
			padding: 0;

			> div {
				padding: 10px;
			}

			&:nth-child(odd) {
				background: @off-white;
			}

			&::after {
				display: none;
			}

			&.highlighted {
				.value {
					background: @sec-bg;
					background: var(--sec-bg);
					color: @sec-fg;
					color: var(--sec-fg);
					flex: 0;
				}
			}
		}

		&.stripe-flipped {
			li {
				&:nth-child(odd) {
					background: #ffffff;
				}

				&:nth-child(even) {
					background: @off-white;
				}
			}
		}
	}

	&.alt-style {
		li {
			flex-wrap: wrap;

			.key {
				.all-caps();
				width: 100%;
				color: @mid-grey;
				font-weight: 400;
				font-size: 11px;
				line-height: 11px;
				letter-spacing: @wide-spacing;
				padding-top: 15px;
				padding-bottom: 5px;
			}

			.value {
				width: 100%;
				text-align: left;
				padding-top: 0;
				padding-bottom: 15px;
				white-space: normal;
				font-weight: 700;
				font-size: 16px;
			}
		}
	}
}

@media screen and (min-width: @screen-sm-min) {
	.key-value-list {
		padding: 20px 30px;

		li {
			padding-top: 25px;

			> div {
				padding-bottom: 25px;
				line-height: 22px;

				&.key {
					font-size: 18px;

					p {
						font-size: 14px;
					}
				}

				&.value {
					font-size: 18px;
				}
			}
		}

		&.with-icons {
			li {
				[class^='icon'],
				[class*='icon'] {
					font-size: 22px;
					height: 22px;
					width: 42px;
					padding-right: 20px;
				}

				&::after {
					left: 42px;
				}
			}
		}

		&.alt-style {
			li {
				.key {
					padding: 20px 15px 15px 15px;
					width: 40%;
				}

				.value {
					padding: 15px;
					width: 60%;
				}
			}
		}
	}
}
