.mx-large-button {
	height: 50px;
	line-height: 50px;
	font-size: 14px;
	padding: 0 24px;

	&.button-with-icon-left,
	&.button-with-icon-right {
		i {
			margin-top: -10px;
			font-size: 20px;
			line-height: 20px;
			width: 20px;
			height: 20px;
		}

		&.icon-only {
			i {
				margin-left: -10px;
			}
		}
	}

	&.button-with-icon-left {
		padding-left: 48px;

		i {
			left: 16px;
		}
	}

	&.button-with-icon-right {
		padding-right: 48px;

		i {
			right: 16px;
		}
	}
}

.mx-standard-button {
	height: 36px;
	line-height: 36px;
	padding: 0 18px;
	font-size: 12px;
}

.button {
	.mx-standard-button();
	font-family: @system-font;
	color: @pri-fg;
	color: var(--pri-fg);
	background: @pri-bg;
	background: var(--pri-bg);
	letter-spacing: @wide-spacing;
	display: inline-block;
	text-transform: uppercase;
	white-space: nowrap;
	border-radius: 3px;
	position: relative;
	font-weight: 400;
	overflow: hidden;
	cursor: pointer;
	text-align: center;
	border: none;
	transition: all 0.1s linear 0s;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
	user-select: none;

	* {
		pointer-events: none;
	}

	&::after {
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		position: absolute;
		transition: all 0.2s ease-in-out 0s;
		background: rgba(255, 255, 255, 0.2);
	}

	.loading-spinner {
		border: 2px solid;
		width: 20px;
		height: 20px;
		margin-top: -10px;
		margin-left: -10px;
		border-color: rgba(255, 255, 255, 0.25);
		border-left-color: #ffffff;
		background: transparent;
		display: none;
	}

	&:hover {
		&::after {
			opacity: 1;
		}
	}

	&.button-with-icon-left,
	&.button-with-icon-right {
		i {
			position: absolute;
			top: 50%;
			margin-top: -7px;
			font-size: 14px;
			line-height: 14px;
			width: 14px;
			height: 14px;
			display: block;
			transition: all 0.2s ease-in-out 0s;
		}

		&.icon-only {
			i {
				left: 50%;
				right: initial !important;
				margin-left: -7px;
			}
		}

		.text {
			display: inline-block;
			transition: all 0.2s linear 0s;
			opacity: 1;
		}
	}

	&.button-with-icon-left {
		padding-left: 38px;

		i {
			left: 10px;
		}
	}

	&.button-with-icon-right {
		padding-right: 38px;

		i {
			right: 10px;
		}
	}

	&.block-button {
		display: block;
		width: 100%;
	}

	&.large-button {
		.mx-large-button();
	}

	&.small-button {
		height: 30px;
		line-height: 30px;
		font-size: 10px;
		padding: 0 12px;

		&.button-with-icon-left {
			padding-left: 32px;

			i {
				left: 8px;
			}
		}

		&.button-with-icon-right {
			padding-right: 32px;

			i {
				right: 8px;
			}
		}
	}

	&.secondary-button {
		color: @sec-fg;
		color: var(--sec-fg);
		background: @sec-bg;
		background: var(--sec-bg);
	}

	&.positive-button {
		color: @pos-fg;
		color: var(--pos-fg);
		background: @pos-bg;
		background: var(--pos-bg);
	}

	&.minor-button {
		background: @lighter-grey;
		color: @mid-grey;

		&:hover {
			background: @light-grey;
			color: @dark-grey;
		}

		&::after {
			display: none;
		}
	}

	&.negative-button {
		color: @neg-fg;
		color: var(--neg-fg);
		background: @neg-bg;
		background: var(--neg-bg);
	}

	&.disabled {
		background: @light-grey !important;
		color: @off-white !important;
		cursor: not-allowed;
		pointer-events: none;

		&::after {
			display: none;
		}
	}

	&.loading {
		color: rgba(255, 255, 255, 0) !important;

		.loading-spinner {
			display: block;
		}
	}

	&.button-margin-left {
		margin-left: 5px;
	}
}
