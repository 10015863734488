ul.labelled-list {
	font-size: 12px;

	li {
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;
		line-height: 1.3em;

		.label {
			color: @mid-grey;
			padding-right: 20px;
		}

		.value {
			font-weight: 700;
			letter-spacing: @slim-spacing;
			color: @dark-grey;
			text-align: right;
		}

		&.headline-value {
			.label {
				font-weight: 700;
				color: @dark-grey;
				letter-spacing: @slim-spacing;
				font-size: 14px;
			}

			.value {
				color: @text-hl;
				color: var(--text-hl);
				font-size: 14px;
			}
		}

		&:last-child {
			padding-bottom: 0;
		}
	}
}

@media screen and (min-width: @screen-xs-min) {
	ul.labelled-list {
		li {
			&.headline-value {
				.label,
				.value {
					font-size: 16px;
				}
			}
		}
	}
}

@media screen and (min-width: @screen-sm-min) {
	ul.labelled-list {
		li {
			&.headline-value {
				.label,
				.value {
					font-size: 18px;
				}
			}
		}
	}
}

@media screen and (min-width: @screen-md-min) {
	ul.labelled-list {
		font-size: 14px;

		li {
			padding-bottom: 12px;
		}
	}
}
