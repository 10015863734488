.data-list {

	li {
		background: @off-white;
		display: flex;
		padding: 5px 8px;
		justify-content: flex-start;
		align-items: center;

		.label {
			color: @mid-grey;
			font-weight: 700;
			font-size: 10px;
			padding-right: 10px;
			line-height: 16px;
			width: 40%;
			text-transform: uppercase;
		}

		.value {
			color: @dark-grey;
			font-size: 12px;
			font-weight: 700;
			line-height: 16px;
			width: 60%;
			text-transform: capitalize;
		}

		&:nth-child(even) {
			background: none;
		}

		&.highlight {
			background: @sec-bg;

			.label,
			.value {
				color: @sec-fg;
			}
		}
	}

	&.values-right {

		li {

			.value {
				text-align: right;
			}
		}
	}

	&.padded {

		li {
			margin-bottom: 4px;

			&:nth-child(even) {
				background: @off-white;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.wide-label {

		li {

			.label {
				width: 70%;
			}

			.value {
				width: 30%;
			}
		}
	}

	&.equal-label {

		li {

			.label,
			.value {
				width: 50%;
			}
		}
	}

	&.double-width {
		display: flex;
		flex-flow: row wrap;

		li {
			background: @off-white;
			flex: auto;
			width: 40%;

			&:nth-child(4n-4),
			&:nth-child(4n-5) {
				background: none;
			}
		}

		&.padded {

			li {

				&:nth-child(odd) {
					margin-right: 4px;
				}

				&:nth-last-child(2) {
					margin-bottom: 0;
				}
			}
		}
	}
}
