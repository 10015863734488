.heading {
	margin: 0 auto;

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
		color: @dark-grey;
	}

	p {
		font-size: 12px;
		font-weight: 400;
		line-height: 1.4em;
		color: @mid-grey;
		margin-top: 10px;
	}

	&.has-icon {
		display: flex;
		justify-content: space-between;
		align-items: center;

		i {
			color: @text-hl;
			color: var(--text-hl);
			font-size: 26px;
			height: 26px;
			width: 26px;
		}
	}

	@media screen and (min-width: @screen-sm-min) {
		h1,
		h2,
		h3,
		h4,
		h5 {
			font-size: 18px;
			line-height: 22px;
		}

		p {
			font-size: 14px;
		}
	}

	// @media screen and (min-width: @screen-xs-min) {
	//     margin: 0 auto;
	//     max-width: 375px;
	// }

	@media screen and (min-width: @screen-sm-min) {
		max-width: 600px;
	}

	@media screen and (min-width: @screen-md-min) {
		max-width: 1000px;
	}
}
