.icon-message {

	width: 100%;
	padding-top: 100px;
	position: relative;
	flex-shrink: 0;

	> i {
		display: block;
		position: absolute;
		font-size: 80px;
		color: @light-grey;
		width: 100%;
		line-height: 80px;
		height: 80px;
		text-align: center;
		top: 0;
	}

	> .written-content {
		text-align: center;
		padding: 0 30px 0;

		> p {
			text-align: justify;
			text-align-last: center;
		}
	}
}

@media screen and (min-width: @screen-sm-min) {

	.error-message {

		padding-top: 120px;

		> i {
			font-size: 100px;
			line-height: 100px;
			height: 100px;
		}
	}
}
