.section-grid-container {
	display: flex;
	flex-direction: column;
	width: 1000px;
	margin: 0 auto;

	.section-grid {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		margin: 0 -5px;
		align-items: stretch;

		.section-collection {
			padding: 0 5px;
		}

		.side-column-left {
			order: 0;
			width: 25%;
		}

		.center-column {
			order: 1;
			width: 50%;
		}

		.side-column-right {
			order: 2;
			width: 25%;
		}

		.half-column {
			width: 50%;
		}
	}
}
