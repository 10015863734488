//-----  COLORS  -----//

.pri-fg {
	color: @pri-fg !important;
	color: var(--pri-fg) !important;
}

.sec-fg {
	color: @sec-fg !important;
	color: var(--sec-fg) !important;
}

.head-fg {
	color: @head-fg !important;
	color: var(--head-fg) !important;
}

.pos-fg {
	color: @pos-fg !important;
	color: var(--pos-fg) !important;
}

.warn-fg {
	color: @warn-fg !important;
	color: var(--warn-fg) !important;
}

.neg-fg {
	color: @neg-fg !important;
	color: var(--neg-fg) !important;
}

.text-hl {
	color: @text-hl !important;
	color: var(--text-hl) !important;
}

//-----  BACKGROUNDS  -----//

.pri-bg {
	background: @pri-bg !important;
	background: var(--pri-bg) !important;
}

.sec-bg {
	background: @sec-bg !important;
	background: var(--sec-bg) !important;
}

.head-bg {
	background: @head-bg !important;
	background: var(--head-bg) !important;
}

.pos-bg {
	background: @pri-bg !important;
	background: var(--pos-bg) !important;
}

.warn-bg {
	background: @warn-bg !important;
	background: var(--warn-bg) !important;
}

.neg-bg {
	background: @neg-bg !important;
	background: var(--neg-bg) !important;
}
