.inner-panel {
	border: 1px solid @light-grey;
	border-radius: 3px;
	overflow: hidden;

	.inner-panel-section {
		padding: 15px;
		background: #fff;
		display: block;

		h4 {
			font-size: 14px;
			line-height: 1.3em;
			margin: -5px 0;
		}

		&.alt {
			background: @off-white;
		}
	}

	a.inner-panel-section {
		transition: all 0.2s linear 0s;

		&:hover {
			background: @off-white;

			&.alt {
				background: #fff;
			}
		}
	}

	.inner-panel-separator {
		height: 1px;
		background: @light-grey;

		&:first-child,
		&:last-child {
			display: none;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}

@media screen and (min-width: @screen-xs-min) {
	.inner-panel {
		.inner-panel-section {
			padding: 20px;
		}
	}
}

@media screen and (min-width: @screen-md-min) {
	.inner-panel {
		.inner-panel-section {
			padding: 30px;
		}
	}
}
